import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { BehaviorSubject } from 'rxjs';
import { Soggetto } from '../models/soggetto.model';

@Injectable({
  providedIn: 'root'
})
export class SoggettoService {
  constructor(private http: HttpClient) { }
  private _soggetto: BehaviorSubject<Soggetto | null> = new BehaviorSubject<Soggetto | null>(null);

  get soggetto(): Soggetto | null {
    return this._soggetto.value;
  }

  get soggettoAsObservable(): BehaviorSubject<Soggetto | null> {
    return this._soggetto;
  }

  set soggetto(data: Soggetto | null) {
    this._soggetto.next(data);
  }

  getSoggetto(cf_soggetto: string, descrizioneComune: boolean = false) {
    return this.http.get<any>(`${environment.api}/soggetto/${cf_soggetto}`, {params: {"descrizione_comune": descrizioneComune}});
  }
}
