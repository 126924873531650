<footer class="footer py-4">
    <div class="container">
        <section class="d-flex flex-row justify-content-between flex-wrap">
            <article class="col-12 col-lg-auto d-flex flex-row">
                <img src="../../../assets/img/logos/logo_italiae_w.png" class="logo">
                <span>Amministrazione Trasparente per il cittadino</span>
            </article>
            <article class="col-12 col-lg-auto mt-4 mt-lg-auto">
                <b>Comunica con Italiae</b>
                <p>
                    <a href="/supporto?type=technical">Richiesta generica di soluzione per
                        problematiche di
                        funzionamento della
                        piattaforma</a><br>
                    <a href="/supporto?type=request">Richiesta di chiarimenti interpretativi sui
                        contenuti
                        della piattaforma</a>
                </p>
            </article>
        </section>

        <hr>

        <div class="d-flex flex-row">
            <nav class="navbar navbar-expand-lg links">
                <div class="container">
                    <div class="d-flex flex-row col-12">
                        <ul nz-menu nzMode="horizontal">
                            <li nz-menu-item *ngFor="let page of pages" class="col-12 col-lg-auto">
                                <a [href]="page.path" target="_blank">{{ page.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</footer>