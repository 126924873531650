import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  list = [
    { name: 'Home', icon: 'home', link: '/home' },
    { name: 'About', icon: 'info', link: '/about' },
    { name: 'Contact', icon: 'contact_phone', link: '/contact' },
  ];

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
