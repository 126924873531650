import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from '../helpers/interceptor.service';
import { Credentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response';
import { HttpContextConfig } from '../models/http-context-config';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  currentUser: BehaviorSubject<User | any> = new BehaviorSubject(null);

  login(credentials: Credentials) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/auth/login`, credentials);
  }

  logout() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/auth/logout`);
  }

  me() {
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`);
  }

  getCurrentUser(user: User) {
    this.currentUser.next(user);
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`, { context: context });
  }
}
