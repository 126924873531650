<nz-collapse (click)="togglePanelCollapse($event)" [nzGhost]="nzGhost" [nzExpandIconPosition]="position"
  [style.background-color]="'white'">
  <nz-collapse-panel [nzHeader]="header" [nzActive]="active">
    <ng-content></ng-content>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #header>
  <section class=" d-flex flex-column">
    <h6>{{ title }}</h6>
    <p class="fw-normal">{{ subtitle }}</p>
  </section>
</ng-template>