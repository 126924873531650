// Angular
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpContextToken,
  HttpContext,
} from '@angular/common/http';
// RxJS
import { map, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { toast } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpContextConfig } from '../models/http-context-config';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */

export const CONFIG = new HttpContextToken<HttpContextConfig>(
  () => new HttpContextConfig()
);

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private translate: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let storageData = localStorage.getItem('csrf') || '{}';

    if (storageData) {
      request = request.clone({
        setHeaders: {
          csrf: storageData,
          'ngrok-skip-browser-warning': 'true',
        },
        withCredentials: true,
      });
    } else {
      request = request.clone({
        withCredentials: true,
      });
    }

    // check if the request have NO_SPINNER context
    if (request.context.get(CONFIG)) {
      let config = request.context.get(CONFIG);
      if (!config.noSpinner) {
        this.loaderService.setLoading(true, request.url);
      }
      if (config.isPublic) {
        // console.log("this is a public request");
        /*this.translate.get(['toast.info.100_TITLE', 'toast.info.100_TEXT']).subscribe(message =>
          toast.fire({
            title: message['toast.info.100_TITLE'],
            text: message['toast.info.100_TEXT'],
            icon: 'info'
          }
          )
        );*/
      }
    }

    return next
      .handle(request)
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.loaderService.setLoading(false, request.url);
          }
          return evt;
        })
      )
      .pipe(
        tap((event) => {}),
        catchError((err) => {
          //TODO: scommentare
          this.loaderService.setLoading(false, request.url);
          if (new RegExp('[4,5]0[0-5]').test(err.status)) {
            // this.translate.get([`TOAST.ERROR.${err.status}_TITLE`, `TOAST.ERROR.${err.status}_TEXT`]).subscribe(message =>
            // toast.fire({
            //   title: message[`TOAST.ERROR.${err.status}_TITLE`],
            //   text: message[`TOAST.ERROR.${err.status}_TEXT`],
            //   icon: 'error'
            // }
            // ));
          } else {
            // this.translate.get(['TOAST.ERROR.general_TITLE', 'TOAST.ERROR.GENERAL_TEXT']).subscribe(message =>
            //   toast.fire({
            //     title: message['TOAST.ERROR.GENERAL_TITLE'],
            //     text: message['TOAST.ERROR.GENERAL_TEXT'],
            //     icon: 'error'
            //   }
            //   ));
          }

          if (err.status === 401) {
            // this.router.navigate(['/auth/login']);
          }
          if (err.status === 403) {
            history.back();
          }
          return throwError(err.error);
        })
      );
  }
}
