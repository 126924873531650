<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'horizontal'">
    <section class="badge p-4">
      <article class="col-2 pe-1" *ngIf="!disableIcon">
        <img [ngClass]="largeIcon ?  'icon icon-xlg' : 'icon icon-lg'" [src]="icon">
      </article>
      <article>
        <h1 class="text-wrap"
          [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
          [innerHTML]="title"></h1>
        <p class="text-wrap fw-bold"
          [ngClass]="{'fs-5': !title, 'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
          [innerHTML]="subtitle"></p>
        <p class="text-wrap"
          [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
          [innerHTML]="description"></p>

        <article *ngIf="url"
          [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}">
          <a class="mt-3 mt-lg-5 fw-bold" (click)="goTo(url)">
            {{ customUrlLabel ?? 'Vai al dettaglio dei dati' }}
          </a>
        </article>
      </article>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'counter'">
    <section class="badge flex-column justify-content-start p-4">
      <article class="col-12 d-flex flex-row align-items-start">
        <img class="icon icon-xlg" [src]="icon" style="width: 5rem;">
      </article>
      <article>
        <app-counter [maxValue]="title" [unit]="unit" [addend]="addend"></app-counter>
        <p class="text-wrap d-block"
          [ngClass]="{'fs-5': !title, 'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
          [innerHTML]="subtitle"></p>
        <p class="text-wrap"
          [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
          [innerHTML]="description"></p>

        <article *ngIf="url"
          [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}">
          <a class="mt-3 mt-lg-5 fw-bold" [routerLink]="url">{{customUrlLabel ?? 'Vai al dettaglio dei dati'}}</a>
        </article>
      </article>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'vertical-xl'">
    <section class="vertical_badge p-4">
      <div class="d-flex flex-column align-items-center">
        <article>
          <img [ngClass]="largeIcon ?  'icon-xlg' : 'icon-lg'" class="icon" [src]="icon">
        </article>
        <article class="p-10">
          <h1
            [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
            [innerHTML]="title"></h1>
          <p class="fw-bold vertical"
            [ngClass]="{'fs-5': !title, 'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
            [innerHTML]="subtitle"></p>
          <p class="vertical"
            [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
            [innerHTML]="description"></p>
        </article>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'vertical-sm'">
    <section class="vertical-small-badge p-4">
      <div class="d-flex flex-column align-items-center">
        <article class="py-2">
          <img [ngClass]="largeIcon ?  'icon-xlg' : 'icon-sm'" class="icon" [src]="icon">
        </article>
        <article class="">
          <h1
            [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
            [innerHTML]="title"></h1>
          <p class="fw-bold vertical"
            [ngClass]="{'fs-5': !title, 'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
            [innerHTML]="subtitle"></p>
          <p class="vertical"
            [ngClass]="{'text-start': textAlign == 'left', 'text-center': textAlign == 'center', 'text-end': textAlign == 'right'}"
            [innerHTML]="description"></p>
        </article>
      </div>
    </section>
  </ng-container>
</ng-container>