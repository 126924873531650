<section class="badge align-content-stretch" [ngClass]="color">
  <article class="d-flex flex-row" [ngClass]="{'flex-wrap text-wrap':rightIcon}">
    <article *ngIf="icon" class="d-none d-lg-block header-icon">
      <img [src]="icon">
    </article>
    <article class="p-4 d-flex flex-column justify-content-between">
      <h1 class="mb-1 mr-1 text-wrap" [ngClass]="{'fs-3': col == 6, 'text-capitalize': capitalize}" [innerHTML]="title">
      </h1>
      <div *ngIf="!rightIcon">
        <ng-container *ngIf="category != null">
          <h6 class="mb-0">{{ category }}</h6>
          <p class="text-wrap" [innerHTML]="subtitle"></p>
        </ng-container>
        <ng-container *ngIf="category == null">
          <h6 class="text-wrap" [innerHTML]="subtitle"></h6>
        </ng-container>
        <!-- <p>{{ description}}</p> -->

        <button *ngIf="url" class="btn btn-secundary col-8 mt-3 mt-lg-5" [routerLink]="url">
          <span class="px-5 fw-bold">Scopri di più</span>
        </button>
      </div>

      <button *ngIf="button" class="btn btn-secundary col-8 mt-3 mt-lg-5" [ngClass]="button.classes"
        (click)="button.action()">
        <span class="fw-bold" [innerHTML]="button.text"></span>
        <span><img [src]="'../../../assets/img/icons/right-arrow.svg'"
            style="height: 1rem; margin-left: 0.25rem"></span>
      </button>
    </article>
  </article>
  <article *ngIf="!rightIcon" class="d-none d-lg-block"
    [class]="{'header-image': category, 'background-blue': color == 'light-blue'}">
    <img [src]="'../../../assets/img/header/header-01.svg'">
  </article>
  <article *ngIf="rightIcon" class="d-none d-lg-block pb-md-3" [ngClass]="{'pt-md-5': col != 6}">
    <img [src]="rightIcon" class="w-100">
  </article>
</section>