import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = 'Regione Marche';
  @Input() subtitle: string = 'Semplificare con gli open data';
  @Input() description: string | null = 'L’applicazione consente un accesso semplificato e facilmente fruibile alle informazioni pubblicate dalle singole Pubbliche Amministrazioni nella sezione "Amministrazione Trasparente” sui rispettivi siti web.';
  @Input() url: string | null = null;
  @Input() unit: string = '';
  @Input() addend: number = 1;
  @Input() type: 'vertical-sm' | 'vertical-xl' | 'horizontal' | 'counter' = 'horizontal';
  //Utilizzato per inserire una label personalizzata al posto della stringa 'Vai al dettaglio dei dati'
  @Input() customUrlLabel: string | null = null;
  //Permette di modificare la dimensione dell'icona per la visualizzazione di default del componente (primo template)
  @Input() largeIcon: boolean = false;
  @Input() textAlign: 'left' | 'center' | 'right' = 'center';
  @Input() disableIcon: boolean = false;

  isDemoMode: boolean = false;
  constructor(_router: ActivatedRoute) {
    this.isDemoMode = _router.snapshot.queryParams['demo'] == 'true';
  }

  ngOnInit() {
  }

  goTo(url: string) {
    if (this.isDemoMode) {
      console.warn('Modalità demo attiva, non è possibile aprire il link');
      return;
    }
    window.open(url, '_blank');
  }
}
