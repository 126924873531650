import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Page } from 'src/app/shared/models/page.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BaseComponent } from '../base/base.component';
import { SoggettoService } from 'src/app/shared/services/soggetto.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  currentUser: User | null = null;
  isDemoMode: boolean = false;

  pages: Page[] = [];

  version = 'v1.2.4';
  constructor(public override _router: ActivatedRoute, public override _soggetto: SoggettoService, private _auth: AuthService, private router: Router) {
    super(_router, _soggetto);

    this._auth.currentUser.subscribe(value => {
      this.currentUser = value;
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      let _url = event.urlAfterRedirects;

      this.pages.filter(page => page.selected == true).forEach(page => page.selected = false);

      let el = this.pages.find(page => { return _url.includes(page.path?.split('/').pop()); });
      if (el) el.selected = true;
    });

    this.router.events.subscribe(event => {
      this.changeMenu();
    });

    this._auth.currentUser.subscribe(event => {
      this.changeMenu();
    });
  }

  override ngOnInit(): void {
    this.isDemoMode = this._router.snapshot.queryParams['demo'] == 'true';
    this.changeMenu();
  }

  logout() {
    this._auth.logout().subscribe((res) => {
      this._auth.currentUser.next(null);
      localStorage.removeItem('csrf');
      this.router.navigate(['/']);
    });
  }

  changeMenu() {
    this._soggetto.soggettoAsObservable.subscribe(value => {
      if (value?.id) {
        this.pages = [
          { icon: null, name: this._router.snapshot.queryParams['demo'] == 'true' ? 'Ente demo' : this._soggetto.soggetto?.name!, path: `/${this._soggetto.soggetto?.id}/dashboard`, selected: this.router.url.includes('dashboard') },
          { icon: null, name: 'Bilancio', path: `/${this._soggetto.soggetto?.id}/bilancio`, selected: this.router.url.includes('bilancio') },
          { icon: null, name: 'Personale', path: `/${this._soggetto.soggetto?.id}/personale`, selected: this.router.url.includes('personale') },
          { icon: null, name: 'Servizi erogati', path: `/${this._soggetto.soggetto?.id}/servizi`, selected: this.router.url.includes('servizi') },
          { icon: null, name: 'Patrimonio immobiliare', path: `/${this._soggetto.soggetto?.id}/patrimonio`, selected: this.router.url.includes('patrimonio') },
          //{ icon: null, name: 'Il Progetto', path: `/progetto`, selected: this.router.url.includes('progetto') },
          //{ icon: null, name: 'Per le Pubbliche Amministrazioni', path: `/pubbliche-amministrazioni`, selected: this.router.url.includes('pubbliche-amministrazioni') }
        ];
      } else if (this.router.url.includes('/portale')) {
        this.pages = [
          { icon: null, name: 'Home', path: `/home`, selected: this.router.url.includes('home') },
          { icon: null, name: 'Banca Dati ATC', path: `/portale/banca-dati`, selected: this.router.url.includes('banca-dati') },
          { icon: null, name: 'Approfondisci singolo Ente', path: `/portale/ente`, selected: this.router.url.includes('ente') },
          // { icon: null, name: 'Caricamento dati', path: `/app/upload`, selected: this.router.url.includes('upload') },
        ];
      }
      else {
        this.pages = [
          { icon: null, name: 'Home', path: `/home`, selected: this.router.url.includes('home') },
          { icon: null, name: 'Il Progetto', path: `/progetto`, selected: this.router.url.includes('progetto') },
          { icon: null, name: 'FAQ', path: `/faq`, selected: this.router.url.includes('faq') }
          // { icon: null, name: 'Per le Pubbliche Amministrazioni', path: `/pubbliche-amministrazioni`, selected: this.router.url.includes('pubbliche-amministrazioni') }
        ];
      }
    });
  }

  goTo(url: string) {
    window.open(url, '_blank');
  }

  navigate(url: string) {
    this.router.navigate([url], { queryParamsHandling: 'merge' });
  }
}
