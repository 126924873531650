import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit {
  @Input() position: 'left' | 'right' = 'right';
  @Input() title: string | null = null;
  @Input() subtitle: string | null = null;
  @Input() nzGhost = true;
  @Input() active = true;

  isPanelCollapsed: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  togglePanelCollapse(event: Event): void {
    event.stopPropagation()
    this.isPanelCollapsed = !this.isPanelCollapsed;
  }

}
