import { Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import DataLabelsPlugin, { Context } from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  public activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  @ViewChild(BaseChartDirective) baseChartDirective!: BaseChartDirective;
  @Input() stats: any;
  @Input() details: boolean = false;

  public isPAVisualization: boolean = false;

  annotation: any = {
    type: 'line',
    scaleID: 'x',
    value: 20,
    borderColor: 'orange',
    borderWidth: 2,
    label: {
      display: false,
      position: 'center',
      color: 'orange',
      content: `Media: 20`,
      font: {
        weight: 'bold',
      },
    },
  };

  optionalAnnotations: any = [];

  isVisible: boolean = false;

  public barChartOptions: ChartConfiguration['options'] = {
    indexAxis: 'y',
    locale: 'it-IT',
    scales: {
      x: {
        beginAtZero: true,
        suggestedMin: 0,
        stacked: true,
        ticks: {
          //stepSize: 20,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        clamp: true,
        anchor: 'center',
        align: 'center',
        color: 'black',
        formatter: (value: any, context: any) => {
          let isNegative = context.chart.scales.x.min < 0;
          if (value == null) return isNegative ? 'n.d.       ' : '       n.d.';
          if (this.stats.anagrafica.indicatore == 'ind11_tempest_pagamenti') {
            if (value == -51) return ["Il dato disponibile è anomalo;", "l'indicatore non viene pertanto valorizzato"];
          }

          let result = null;
          if (isNegative && Math.abs(value / context.chart.scales.x.min) < 0.1)
            result =
              Intl.NumberFormat('it-IT').format(value.toFixed(1)) +
              context.dataset.label +
              '                  ';
          if (Math.abs(value / context.chart.scales.x.max) < 0.1) {
            result =
              '                  ' +
              Intl.NumberFormat('it-IT').format(value.toFixed(1)) +
              context.dataset.label;
          }
          if (result == null)
            result =
              Intl.NumberFormat('it-IT').format(value.toFixed(1)) +
              context.dataset.label;
          return result;
        },
        font: {
          weight: 700,
          size: 10,
        },
      },
      annotation: {
        annotations: [this.annotation],
      },
    },
  };
  public barChartLabels = ['2022', '2021', '2020'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartData = [
    {
      data: [0, 0, 0],
      label: '',
      backgroundColor: ['#50fcbc', '#93c8ff', '#a7a7e8'],
    },
  ];
  public barChartPlugins = [DataLabelsPlugin];

  constructor() {
    if (this.activatedRoute.snapshot.params['soggetto'])
      this.isPAVisualization = true;
  }

  ngOnInit() {
    this.changeCanvas();
  }

  changeCanvas() {
    let data: number[] = [];
    let labels: string[] = [];

    this.stats.dati.forEach((element: any) => {
      labels.push(element.anno);

      let value = this.stats.anagrafica.indicatore == 'ind11_tempest_pagamenti' && (element.valore < -50 || element.valore > 300) ? -51 : element.valore;
      data.push(value);
      //if (this.stats.anagrafica.indicatore == 'ind11_tempest_pagamenti') console.log(element.valore, value);

      if (element.unita_misura == '%')
        this.barChartOptions!.scales!['x']!.max = 100;
    });

    //if (this.stats.anagrafica.indicatore == 'ind11_tempest_pagamenti') console.log(data);

    this.barChartData = [
      {
        data: data,
        label: ' ' + this.stats.dati[0].unita_misura,
        backgroundColor: ['#50fcbc', '#93c8ff', '#a7a7e8'],
      },
    ];

    this.annotation.value = this.stats?.media?.media ?? 0;
    this.annotation.label.content = `Media: ${this.stats?.media?.media ?? 0} ${this.stats.dati[0].unita_misura
      }`;

    this.barChartLabels = labels;

    this.baseChartDirective?.update();
  }

  isStatsNull() {
    return this.stats.dati.every((stat: any) => stat.valore == null);
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    //console.log('Button cancel clicked!');
    this.isVisible = false;
  }
}

