import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'drw-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  /**
   * L'array del nome delle colonne da mostrare nella tabella.
   */
  @Input() displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];

  /**
   * EndPoint della chiamata API per popolare la tabella.
   */
  @Input() endpointDataSource!: string;

  /**
   * Flag booleano che permette di rendere visibile la colonna 'azioni'.
   */
  @Input() actions: boolean = false;

  /**
   * È un oggetto con dei valori booleani che permettono di far visualizzare a schermo i diversi bottoni azioni.
   */
  @Input() actionsButton = { info: true, modify: true, trash: true };

  /**
   * Flag booleano che permette di visualizzare il campo di ricerca.
   */
  @Input() isFilter: boolean = false;

  /**
   * Flag booleano che permette di attivare l'ordinamento della tabella.
   */
  @Input() isSortable: boolean = false;

  /**
   * Array di colonne che possono essere soggette ad ordinamento.
   */
  @Input() sortableColumns: string[] = [];

  /**
   * EndPoint della chiamata API per la stampa in formato PDF.
   */
  @Input() endpointPdf!: string;

  /**
   * EndPoint della chiamata API per l'export in formato EXCEL.
   */
  @Input() endpointExcel!: string;

  /**
   * Variabile che permette di ricaricare le colonne.
   */
  @Input() renderColumns!: BehaviorSubject<string>;

  /**
   * Se utilizzare un layout di tabella fisso. L'abilitazione
   * di questa opzione applicherà larghezze di colonna coerenti
   * e ottimizzerà il rendering degli stili permanenti per le tabelle native.
   */
  @Input() fixedLayout: boolean = false;

  /**
   * Evento che passa l'id dell'elemento da eliminare.
   */
  @Output() deletedElement: EventEmitter<string> = new EventEmitter<string>();

  dataSource!: MatTableDataSource<any>;

  // * Paginator
  length!: number;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [5, 10, 25];
  showFirstLastButtons: boolean = true;

  searchForm = this.fb.group({ search: "" });

  // * Testi eliminazione elemento
  title!: string;
  text!: string;
  cancelButton!: string;
  confirmButton!: string;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {
    this.translate.get(['DELETE_CONFIRMATION.TITLE', 'DELETE_CONFIRMATION.TEXT', 'DELETE_CONFIRMATION.CONFIRM', 'DELETE_CONFIRMATION.DISMISS']).subscribe(messages => {
      this.title = messages['DELETE_CONFIRMATION.TITLE'];
      this.text = messages['DELETE_CONFIRMATION.TEXT'];
      this.confirmButton = messages['DELETE_CONFIRMATION.CONFIRM'];
      this.cancelButton = messages['DELETE_CONFIRMATION.DISMISS'];
    });
  }

  ngOnInit(): void {
    if (this.actions) this.displayedColumns.push('azioni');
    this.getData();
    this.renderColumns.subscribe((value: string) => {
      if (value) this.getData();
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData({ skip: this.pageIndex * this.pageSize, take: this.pageSize })
    return event;
  }

  getData(params?: any) {
    if (this.searchForm.value.search) params = { skip: this.pageIndex * this.pageSize, take: this.pageSize, search: this.searchForm.value.search };

    this.http.get<FormattedResponse<any>>(`${environment}/${this.endpointDataSource}`, { params }).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource<any>(response.data);
        this.length = response.total ?? 0;
      }
    });
  }

  deleteElement(id: string) {
    this.deletedElement.emit(id);
  }

  sortData(sort: Sort) {
    this.getData({ skip: this.pageIndex * this.pageSize, take: this.pageSize, sort: sort });
  }

  sortableHeader(headerName?: string): boolean {
    if (this.isSortable && headerName != 'azioni') return true;
    if (this.sortableColumns.findIndex(element => element == headerName) != -1) return true;
    return false;
  }

  exportData(type: 'excel' | 'pdf') {
    switch (type) {
      case 'excel':
        this.http.get(`${environment}/${this.endpointExcel}`);
        break;
      case 'pdf':
        this.http.get(`${environment}/${this.endpointPdf}`);
        break;
      default:
        console.log('Formato non corretto');
        break;
    }
  }
}