import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {
  @Input() position: 'left' | 'right' = 'right';
  @Input() title: string | null = null;
  @Input() subtitle: string | null = null;
  @Input() nzGhost = true;
  @Input() active = true;
  @Input() verticalMode = false;

  constructor() { }

  ngOnInit() {
  }

}
