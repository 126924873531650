import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private http: HttpClient) { }

  startCounters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
