import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-card',
  templateUrl: './header-card.component.html',
  styleUrls: ['./header-card.component.scss']
})
export class HeaderCardComponent implements OnInit {
  @Input() icon: string | null = null;
  @Input() title: string = 'Regione Marche';
  @Input() category: string | null = null;
  @Input() subtitle: string = 'Semplificare con gli open data';
  @Input() description: string | null = 'L’applicazione consente un accesso semplificato e facilmente fruibile alle informazioni pubblicate dalle singole Pubbliche Amministrazioni nella sezione "Amministrazione Trasparente” sui rispettivi siti web.';
  @Input() url: string | null = null;
  @Input() rightIcon: string | null = null;
  @Input() col: number = 12;
  @Input() button: any = null;
  @Input() capitalize: boolean = false;
  @Input() color: string = 'purple';

  constructor() { }

  ngOnInit() {
  }

}
