import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DataTableComponent } from './data-table/data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { CollapseComponent } from './collapse/collapse.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BaseComponent } from './base/base.component';
import { HeaderCardComponent } from './header-card/header-card.component';
import { CardComponent } from './card/card.component';
import { StatsComponent } from './stats/stats.component';
import { NgChartsModule } from 'ng2-charts';
import { FeedbackCardComponent } from './feedback-card/feedback-card.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CounterComponent } from './counter/counter.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

Chart.register(annotationPlugin);
Chart.register(ChartDataLabels);

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    CollapseComponent,
    BreadcrumbsComponent,
    BaseComponent,
    HeaderCardComponent,
    CardComponent,
    StatsComponent,
    FeedbackCardComponent,
    IndicatorComponent,
    CounterComponent,
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    NgZorroAntdModule,
    NgChartsModule,
    AngularEditorModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DataTableComponent,
    CollapseComponent,
    BreadcrumbsComponent,
    BaseComponent,
    HeaderCardComponent,
    CardComponent,
    StatsComponent,
    NgChartsModule,
    FeedbackCardComponent,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    IndicatorComponent,
    CounterComponent,
    AngularEditorModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }
