<hr *ngIf="!details">
<ng-container *ngIf="stats && !isStatsNull(); else noData">
  <label class="text-muted">I dati in pillole</label>

  <section class="d-flex flex-row justify-content-between flex-wrap">
    <article class="col-12" [ngClass]="{'col-lg-5': details, 'col-lg-8': !details}">
      <canvas baseChart [datasets]="barChartData" [plugins]="barChartPlugins" [labels]="barChartLabels"
        [options]="barChartOptions" [legend]="barChartLegend" [type]="barChartType">
      </canvas>
    </article>

    <article class="col-12 px-4 d-flex flex-column justify-content-center"
      [ngClass]="{'col-lg-2': details, 'col-lg-4': !details}">
      <div class="average-box">
        <span class="fw-bold">{{ stats?.media?.media ?? 0 | number:'1.0-1':'it' }} {{ stats?.dati[0].unita_misura }}
        </span>
        <p>Media nazionale su Enti dello stesso tipo <br> - <b>ultimo anno</b> -</p>
        <p *ngIf="this.stats.anagrafica.indicatore == 'ind11_tempest_pagamenti'">
          <i>Dal calcolo della media sono esclusi gli Enti con valori anomali</i>
        </p>
      </div>
    </article>

    <article class="col-12 col-lg-5" *ngIf="details">
      <section>
        <label>Descrizione</label>
        <p>{{ stats?.anagrafica?.descrizione }}</p>
        <!-- <p *ngIf="isPAVisualization && stats.anagrafica.indicatore == 'ind11_tempest_pagamenti'">
          <b><i>In caso di dati errati/incongruenti si richiede all’Ente di effettuare un controllo sui dati pubblicati
              su Open BDAP. Si consiglia di fornire indicazione di quanto rilevato a
              <a href="mailto:infoitaliae.dara@governo.it">infoitaliae.dara&#64;governo.it</a></i></b>
        </p> -->
      </section>

      <section>
        <label>Criterio di valutazione</label>
        <p>{{ stats?.anagrafica?.criterio_valutazione }}</p>
      </section>

      <section>
        <label>Esplora i dati analitici</label>
        <p [innerHTML]="stats?.anagrafica?.esplora_dati">
          <!-- <a [href]="stats?.anagrafica?.esplora_dati">{{ stats?.anagrafica?.esplora_dati }}</a> -->
        </p>
      </section>

      <section>
        <label>Ultimo aggiornamento</label>
        <p>{{ stats?.anagrafica?.ultimo_aggiornamento | date:'dd/MM/YYYY'}}</p>
      </section>
    </article>
  </section>

  <p class="my-3" *ngIf="details; else openDetails">
    <span class="fw-bold">Fonte dati:</span> {{ stats?.anagrafica?.fonte_dati }}
  </p>

  <ng-template #openDetails>
    <p class="mt-3">
      <a class="text-decoration-underline" (click)="showModal()">Descrizione e dettaglio</a>
    </p>
  </ng-template>
</ng-container>

<ng-template #noData>
  <div class="mb-3" [ngSwitch]="true">
    <span
      *ngSwitchCase="this.stats.anagrafica.contesto == 'servizi' && this.stats.anagrafica.enti == 'UNIONI DI COMUNI'">
      <i>Taluni servizi potrebbero non essere stati conferiti e, per questo, i dati non sono disponibili/pubblicati</i>
    </span>
    <span
      *ngSwitchCase="this.stats.anagrafica.contesto == 'patrimonio' && this.stats.anagrafica.enti == 'UNIONI DI COMUNI'">
      <i>L'Unione non dispone di immobili di proprietà e, per questo, i dati non sono disponibili/pubblicati</i>
    </span>
    <span
      *ngSwitchCase="this.stats.anagrafica.contesto == 'personale' && this.stats.anagrafica.enti == 'UNIONI DI COMUNI' && ['ind2_personale_ind_abitanti', 'ind4_dir_su_dipendenti'].includes(this.stats.anagrafica.indicatore)">
      <i>L'Ente non dispone di tale tipologia di personale e, per questo, i dati non sono disponibili/pubblicati</i>
    </span>
    <span *ngSwitchDefault>
      <i>Non sono presenti informazioni per le ultime tre annualità<ng-container
          *ngIf=" this.stats.anagrafica.indicatore=='ind12_gest_patr_immobiliare'">, oppure, in alcuni casi
          circoscritti,
          l'Ente non dispone di immobili di proprietà</ng-container>
      </i>
    </span>
  </div>
</ng-template>

<nz-modal [(nzVisible)]=" isVisible" [nzTitle]="modalHeader" (nzOnCancel)="handleCancel()" [nzWidth]="'75rem'">
  <ng-container *nzModalContent>
    <app-stats [stats]="stats" [details]="true"></app-stats>
  </ng-container>
  <div *nzModalFooter class="d-flex flex-row">
    <button nz-button class="btn-outline-primary" (click)="handleCancel()">Chiudi</button>
  </div>
</nz-modal>

<ng-template #modalHeader>
  <div class="px-0 mx-0">
    <h4 class="p-3 m-0 text-white">{{ stats?.anagrafica?.titolo }}</h4>
  </div>
</ng-template>