import { Injectable, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { Card } from 'src/app/shared/models/card.model';
import { SoggettoService } from 'src/app/shared/services/soggetto.service';

@Injectable()
export class BaseComponentDependences {
  constructor(
  ) { }
}

@Component({
  selector: 'app-base-component',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})

export class BaseComponent implements OnInit {
  cards: Card[] = [];

  regex = {
    name: "^([a-zA-Z\xE0\xE8\xE9\xF9\xF2\xEC\x27\ s]?)+$",
    email: "[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}",
    password: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
    phone: "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{3,4})(?: *x(\\d+))?\\s*$",
    mobile: "^([+]39)?\\s?((313)|(32[0123456789])|(33[013456789])|(35[0123456789])|(34[0123456789])|(36[0368])|(37[0123456789])|(38[0389])|(39[0123]))[\\s-]?([\\d]{7})$",
    vatNumber: "([0-9]{11})",
    fiscal_code: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$",
    url: "https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)",
    vatNumberFiscalCode: "^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$|([0-9]{11})"
  }

  currencyOptions = {
    align: "right",
    allowNegative: false,
    decimal: ",",
    precision: 2,
    prefix: "",
    thousands: "."
  };

  showValidatorsError(form: any, compiled = false) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if ((compiled && control.value) || !compiled) control.markAsTouched({ onlySelf: true });
    });
  }

  optionsMap(options: any, label: string[], name: string[], value: string) {
    return options.map((option: any) => {
      let final_label = '';
      label.forEach(element => {
        final_label += option[element] + ' ';
      });

      let final_name = '';
      name.forEach(element => {
        final_name += option[element] + ' ';
      });

      return { label: final_label, name: final_name, value: option[value] }
    });
  }

  constructor(public _router: ActivatedRoute, public _soggetto: SoggettoService) {
    this.cards = [
      {
        icon: '../../../../../assets/img/icons/bilancio.svg',
        title: 'Bilancio',
        subtitle: 'Conosci come vengono gestite le risorse pubbliche?',
        description: 'I principali dati di bilancio raccontano, in chiave comparata, come l\'Ente gestisce le risorse, seguendone l\'andamento nel tempo.',
        url: `/${this._router.snapshot.params['soggetto']}/bilancio`
      },
      {
        icon: '../../../../../assets/img/icons/personale.svg',
        title: 'Personale',
        subtitle: 'Conosci i dipendenti della tua Amministrazione?',
        description: 'Gli indicatori più significativi sul personale pubblico consentono di conoscerne le caratteristiche e la gestione che ne fa la tua Amministrazione.',
        url: `/${this._router.snapshot.params['soggetto']}/personale`
      },
      {
        icon: '../../../../../assets/img/icons/servizi-erogati.svg',
        title: 'Servizi erogati',
        subtitle: 'Conosci i principali servizi della tua Amministrazione?',
        description: 'I dati pubblicati sui servizi erogati consentono di misurare quanto spende la tua Amministrazione in termini pro capite per le singole tipologie di servizi.',
        url: `/${this._router.snapshot.params['soggetto']}/servizi`
      },
      {
        icon: '../../../../../assets/img/icons/patrimonio-immobiliare.svg',
        title: 'Patrimonio immobiliare',
        subtitle: 'Conosci il patrimonio immobiliare pubblico della tua Amministrazione e come viene valorizzato?',
        description: 'L’indicatore consente di verificare la valorizzazione del patrimonio immobiliare pubblico che l’Ente gestisce per conto dei cittadini.',
        url: `/${this._router.snapshot.params['soggetto']}/patrimonio`
      }
    ];
  }

  ngOnInit() {
  }

  getSoggetto() {
    let id = this._router.snapshot.params['soggetto'];

    this._soggetto.getSoggetto(id!).subscribe({
      next: (response) => {
        this._soggetto.soggetto = { id, name: response?.denominazione_soggetto?.toLowerCase() }
      }
    })
  }

  getNestedKey(row: any, key: string) {
    let keys = key.split('.');
    let value = row;
    if (keys.length > 1) {
      keys.forEach((key) => {
        if (value[key] != undefined || value[key] != null) {
          value = value[key]
        }
      })
    } else {
      value = row[key];
    }
    return typeof (value) != 'object' ? value : null;
  }
}
