<nav class="navbar navbar-expand-lg navbar-blue py-0">
  <section class="container">
    <article class="d-none d-lg-block">
      <!-- <img src="../../../assets/img/logos/logo_unione_europea.png" class="logo">
      <img src="../../../assets/img/logos/logo_agenzia_coesione_territoriale.png" class="logo">
      <img src="../../../assets/img/logos/logo_affari_regionali_small.png" class="logo">
      <img src="../../../assets/img/logos/logo_pon_w.png" class="logo"> -->
      <img src="../../../assets/img/logos/header.png" class="loghi">
    </article>

    <!-- TODO: menu mobile -->
    <!-- <article class="btn-group">
      <button type="button" class="btn py-0"
        [ngClass]="{'dropdown-toggle dropdown-toggle-split': currentUser, 'logged': currentUser?.email, 'unlogged': !currentUser?.email }"
        [attr.data-bs-toggle]="currentUser ? 'dropdown' : ''" aria-expanded="false"
        [routerLink]="!currentUser ? '/auth/login' : []">
        <button type="button" class="btn">
          <span [class]="{'user-icon-logged': currentUser, 'user-icon-unlogged': !currentUser}">
            <img [src]="'../../../assets/img/icons/user-' + (currentUser ? 'logged' : 'unlogged') + '.svg'">
          </span>
          <span class="login ms-1 fw-bold">
            {{ currentUser?.email || 'Accedi' }}
          </span>
        </button>
      </button>

      <ul class="custom-dropdown dropdown-menu">
        <li>
          <span class="cursor-pointer" (click)="logout()">Esci</span>
        </li>
      </ul>
    </article> -->
  </section>
</nav>

<nav class="navbar navbar-expand-lg pt-5 pb-2 d-none d-lg-block">
  <div class="container">
    <div class="d-flex flex-row col-12">
      <a class=" navbar-brand" (click)="goTo('https://www.italiae.affariregionali.it/home/')">
        <img class="logo" src="../../../assets/img/logos/logo_italiae.png">
      </a>

      <div class="nav-title">
        Amministrazione<br>
        Trasparente<br>
        per il cittadino
      </div>
    </div>
  </div>
</nav>

<nav class="navbar navbar-expand-lg links py-0 d-none d-lg-block">
  <div class="container">
    <div class="d-flex flex-row col-12">
      <ul nz-menu nzMode="horizontal">
        <li nz-menu-item *ngFor="let page of pages" (click)="navigate(page.path!)" [nzSelected]="page.selected">
          <span class="text-capitalize">{{ page.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</nav>

<nav class="navbar bg-white fixed-top d-block d-lg-none">
  <div class="container justify-content-start">
    <button class="btn btn-outline-secundary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand ms-3" href="app/dashboard">
      <img class="logo" src="../../../assets/img/logos/logo_italiae.png">
    </a>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header justify-content-end">
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-start flex-grow-1 pe-3">
          <li class="nav-item" *ngFor="let page of pages">
            <a class="nav-link active" aria-current="page" (click)="navigate(page.path!)">
              <span class="text-capitalize">{{ page.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>