<section class="feedcard col-12">
  <article class="d-flex flex-row justify-content-between w-100">
    <article class="p-4">
      <h1 class="mb-1 mr-1 text-capitalize text-wrap">{{ title }}</h1>
      <div class="pt-3 pt-lg-5">
        <button type="button" class="btn btn-outline-white m-1" (click)="sendFeedback()">
          No
        </button>
        <button type="button" class="btn btn-white m-1" (click)="sendFeedback()">
          Sì
        </button>
      </div>
    </article>
    <article *ngIf="rightIcon" class="d-none d-lg-block p-3">
      <img [src]="rightIcon">
    </article>
  </article>
</section>