<div class="container">
    <div class="d-flex flex-row justify-content-between">
        <mat-form-field appearance="standard" *ngIf="isFilter">
            <mat-label>{{"FILTER" | translate}}</mat-label>
            <form [formGroup]="searchForm" class="col-6 mx-0 px-0">
                <input matInput type="text" class="col-9 mx-0" formControlName="search" (change)="getData()" #input>
            </form>
        </mat-form-field>
        <div class="d-flex align-items-center">
            <button *ngIf="endpointPdf" class="btn btn-outline-danger margin-actions-button"
                (click)="exportData('pdf')"><i class="fa-regular fa-file-pdf"></i> {{'pdf' | uppercase}}</button>
            <button *ngIf="endpointExcel" class="btn btn-outline-success" (click)="exportData('excel')"><i
                    class="fa-regular fa-file-excel"></i> {{'excel' | uppercase}}</button>
        </div>
    </div>

    <div>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            [fixedLayout]="fixedLayout">

            <!-- Columns -->
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!sortableHeader(column)"> {{column |
                    titlecase}}
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="actions && column == 'azioni'; else noActions">
                        <div class="d-flex flex-row">
                            <button *ngIf="actionsButton.info" class="btn btn-success margin-actions-button"><i
                                    class="fa-solid fa-circle-info"></i></button>
                            <button *ngIf="actionsButton.modify" class="btn btn-primary margin-actions-button"><i
                                    class="fa-regular fa-pen-to-square"></i></button>
                            <button *ngIf="actionsButton.trash" class="btn btn-danger" [swal]="{ 
                                    title: title,
                                    text: text,
                                    showCancelButton: true, 
                                    icon: 'question',
                                    confirmButtonColor: '#dd6b55',
                                    cancelButtonText: cancelButton,
                                    confirmButtonText: confirmButton}" (confirm)="deleteElement(row['id'])">
                                <i class="fa-regular fa-trash-can"></i>
                            </button>

                        </div>
                    </div>
                    <ng-template #noActions>
                        {{row[column]}}
                    </ng-template>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="100%">
                    <p class="text-center">{{'NO_DATA_FOUND' | translate}}</p>
                </td>
            </tr>
        </table>

        <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
            [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
            aria-label="Select page">
        </mat-paginator>
    </div>

</div>