import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, interval, map } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Input() counter = 0;
  @Input() maxValue: number | string = 300;
  @Input() unit?: string;
  @Input() addend: number = 1;

  timerSubscription!: Subscription;

  constructor(private _utils: UtilsService) {
  }

  ngOnInit() {
    this._utils.startCounters.subscribe(value => {
      if (value) this.activateCounter();
    })
  }

  increment() {
    if ((this.counter + this.addend) > Number(this.maxValue)) this.counter = Number(this.maxValue);
    else this.counter += this.addend;

    if (this.counter >= Number(this.maxValue)) {
      this.timerSubscription.unsubscribe();
    }
  }

  activateCounter() {
    this.timerSubscription = interval(20)
      .pipe(map(() => this.increment()))
      .subscribe();
  }
}
